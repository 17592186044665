export default {
    meta : {
        title : "PixelHaha",
        keywords : "Générateur d'art IA inspiré par votre idée",
        description : "Générateur d'art IA inspiré par votre idée",

    },
    siteName : "PixelHaha",
    creation : "Image IA",
    product : "Produit",
    magicBrush : "Pinceau magique",
    pricing : "Tarifs",
    freeCredits : "Crédits gratuits",
    credits : "Crédits",
    getMoreCredits : "Obtenez plus de crédits",
    left : "Gauche",
    upgradeToPremium : "Passer à Premium",
    go : "Aller!",
    accountInformation : "Informations sur le compte",
    signOut : "Se déconnecter",
    getMore : "Obtenez plus",
    contactSupport : "Contacter le support",
    history : "Histoire",
    login : "Se connecter",
    signup : "S'inscrire",
    aiFuture : "Fonctionnalité IA",
    aIImageGenerator : "Générateur d'images IA",
    imageEnhacner : "Amélioration d'image par l'IA",
    videoEnhacner : "Amélioration vidéo par l'IA",
    language : "Langue",
    support : "Soutien",
    userPolicy : "Politique d'utilisation",
    privacyPolicy : "politique de confidentialité",
    refundPolicy : "Politique de remboursement",
    blog : "Blog",
    helpAndFAQS : "Aide et FAQ",
    siteMap : "Plan du site",
    bussiness : "Entreprise",
    affiliateProgram : "Programme d'affiliation",
    api : "API",
    contactUs : "Contactez-nous",
    details : "Détails >",
    chooseFace : "Choisissez le visage",
    tryAgain : "Essayer à nouveau",
    choose : {
        delText : 'Etes-vous sûr de vouloir supprimer ce visage ?',
        leftBtnText : 'Continuer',
        rightBtnText : 'Annuler',
        overFaces : 'Le téléchargement des visages a atteint la limite, veuillez supprimer les autres visages pour continuer.',
        faceAn : 'Analyse du visage...',
        facesuccess : 'Succès de l\'analyse du visage',
        facefailed : 'Analyse du visage échouée',
        feedback : 'Merci pour votre retour !',
        copy : 'Copie réussie !',
        batchsupportAIGirl : 'Prise en charge par lots ! Vous pouvez créer une nouvelle image IA quel que soit le résultat actuel.',
        submitting : 'Soumission',
        queuing : 'File d\'attente',
        creating : 'Créer',
        downloading : 'Téléchargement',
        retryText : 'Une erreur s\'est produite, veuillez réessayer plus tard.',
        retry : 'Réessayer',
        emailTip : 'Veuillez saisir un suffixe de nom de domaine valide (tel que .com, .net, etc.)',
        noaccount : 'Pas encore de compte ?',
        enterPassWord : 'Entrez le mot de passe',
        passWordErr : 'Définir un mot de passe de 8 caractères minimum',
        loginPassWordErr : 'Le mot de passe doit contenir au moins 8 caractères',
        forgotpass : 'Mot de passe oublié ?',
        googlesign : 'Se connecter avec Google Sign-In ?',
        resetpassword : 'Réinitialiser le mot de passe',
        readyaccount : 'Vous avez déjà un compte ?',
        confirm : 'Confirmer',
        resetpasswordsuccess : 'Réinitialisation du mot de passe réussie !',
        senderror : 'Envoyer une erreur !',
        sendsuccess : 'Envoyer avec succès !',
        signupfree : 'Inscrivez-vous gratuitement',
        signupsuccess : 'Inscription réussie !',
        sendcode : 'Envoyer le code',
        loginsuccess : 'Connectez-vous avec succès!',
        logingerror : 'Erreur de connexion!',
        aboutS : 'à propos de {leftTime}s',
        keepPatient : 'Soyez patient !',
        needMoretime : 'Besoin de plus de temps !',
        aimostDoneKeep : 'Presque fini ! Soyez patient !',
        usernamePlace : "utilisateur{'@'}exemple.com",
        emailCode : 'Le code de vérification',

    },
    feedbackDialog : {
        title : 'Nous avons besoin de vos commentaires pour améliorer PixelHaha.',
        subTitle1 : 'Vous voulez vous améliorer ?',
        option1 : 'Analyse faciale inexacte concernant les fichiers téléchargés',
        option2 : 'Ça ne ressemble pas à',
        option3 : 'Problèmes de détails du visage (déformés, yeux, bouche, etc.)',
        option4 : 'Lenteur du temps',
        option5 : 'Résultat Netteté',
        txtOption1 : 'Contenu sensible ou dérangeant',
        txtOption2 : 'Violation des conditions de service',
        txtOption3 : 'Pertinence rapide',
        txtOption4 : 'Visage déformé',
        txtOption5 : 'Contenu de l\'image',
        txtOption6 : 'Netteté de l\'image',
        txtOption7 : 'Le temps lent',
        histOption1 : 'Résultats médiocres',
        histOption2 : 'Netteté de l\'image',
        histOption3 : 'Le temps lent',
        histOption4 : 'Contenu de l\'image',
        subTitle2 : 'Vous voulez en dire plus ?',
        placeholder : 'Autres suggestions...',
        submit : 'Soumettre',

    },
    infoDialog : {
        advancedFeatures : 'Fonctionnalités avancées',
        choosePlan : 'Choisissez un plan',
        insufficientCredits : 'Crédits insuffisants',
        insufficientCreditsText : 'Votre solde créditeur :',
        getMoreCredits : 'Obtenez plus de crédits',
        videoTooBig : 'La vidéo est trop grande',
        videoTooLong : 'La vidéo est trop longue',
        videoCannotExceed : 'La vidéo ne peut pas dépasser',
        inFileSize : 'en taille de fichier',
        selectAnotherFile : 'Sélectionnez un autre fichier',
        photoTooBig : 'La photo est trop grande',
        photoCannotExceed : 'La photo ne peut pas dépasser',
        tenMin : '10 minutes',
        deleteFile : 'Etes-vous sûr de supprimer ce fichier ?',
        cancel : 'Annuler',
        confirm : 'Confirmer',

    },
    allRightsReserved : "Tous droits réservés.",
    aiGirlIntroductionDetails : "Présentation détaillée de l'image AI",
    aiGirlHowToUse : "Comment utiliser l'image AI",
    aiGirlFAQDetails : "FAQ sur les images AI Détails",
    lookslikeUploadOpps : {
        title : 'Oups !',
        subtitle : 'Oups ! Vous avez dépassé les limites de crédits gratuits ! Veuillez vous abonner à un forfait pour utiliser toutes les fonctionnalités de manière illimitée.',
        unlockLimit : 'Limite de déverrouillage',

    },
    selectStyle : "Sélectionnez le style",
    purchasePopup : {
        title : 'Avec succès !',
        consumerSuccess : "Vous avez gagné <xx1> crédits",
        subscribeSuccess : 'Accès illimité ! Profitez de notre service !',
        ok : 'D\'ACCORD',

    },
    affiliatePopup : {
        title : 'Tutoriel sur le programme d\'affiliation',
        first : 'D\'abord',
        second : 'Deuxième',
        linkHint : 'Lien vers le programme d\'affiliation :',
        copy : 'Copie',
        ok : 'D\'ACCORD',

    },

}
