export default {
    siteName : "ピクセルハハ",
    creation : "AI画像",
    product : "製品",
    magicBrush : "マジックブラシ",
    pricing : "価格",
    freeCredits : "無料クレジット",
    credits : "クレジット",
    getMoreCredits : "より多くのクレジットを獲得",
    left : "左",
    upgradeToPremium : "プレミアムにアップグレード",
    go : "行く！",
    accountInformation : "アカウント情報",
    signOut : "サインアウト",
    getMore : "さらに詳しく",
    contactSupport : "サポートへのお問い合わせ",
    history : "歴史",
    login : "ログイン",
    signup : "サインアップ",
    aiFuture : "AI機能",
    aIImageGenerator : "AI画像ジェネレーター",
    imageEnhacner : "AI画像エンハンサー",
    videoEnhacner : "AIビデオエンハンサー",
    language : "言語",
    support : "サポート",
    userPolicy : "ユーザーポリシー",
    privacyPolicy : "プライバシーポリシー",
    refundPolicy : "返金ポリシー",
    blog : "ブログ",
    helpAndFAQS : "ヘルプとよくある質問",
    siteMap : "サイトマップ",
    bussiness : "ビジネス",
    affiliateProgram : "アフィリエイトプログラム",
    api : "API",
    contactUs : "お問い合わせ",
    details : "詳細 >",
    chooseFace : "フェイスを選択",
    tryAgain : "もう一度やり直してください",
    choose : {
        delText : 'この顔を削除してもよろしいですか?',
        leftBtnText : '続く',
        rightBtnText : 'キャンセル',
        overFaces : 'アップロードできる顔の数が制限に達しました。続行するには他の顔を削除してください。',
        faceAn : '顔分析中...',
        facesuccess : '顔分析成功',
        facefailed : '顔分析に失敗しました',
        feedback : 'フィードバックありがとうございます!',
        copy : 'コピー成功しました!',
        batchsupportAIGirl : 'バッチ処理に対応！現在の結果に関係なく、新しい AI 画像を作成できます。',
        submitting : '提出中',
        queuing : 'キューイング',
        creating : '作成',
        downloading : 'ダウンロード中',
        retryText : '問題が発生しました。しばらくしてからもう一度お試しください。',
        retry : 'リトライ',
        emailTip : '有効なドメイン名サフィックス（.com、.net など）を入力してください。',
        noaccount : 'まだアカウントがありませんか？',
        enterPassWord : 'パスワードを入力してください',
        passWordErr : 'パスワードを8文字以上に設定する',
        loginPassWordErr : 'パスワードは8文字以上でなければなりません',
        forgotpass : 'パスワードをお忘れですか？',
        googlesign : 'Google サインインでログインしますか?',
        resetpassword : 'パスワードをリセット',
        readyaccount : 'すでにアカウントをお持ちですか?',
        confirm : '確認する',
        resetpasswordsuccess : 'パスワードのリセットに成功しました!',
        senderror : '送信エラー!',
        sendsuccess : '送信成功！',
        signupfree : '無料でサインアップ',
        signupsuccess : 'サインアップ成功！',
        sendcode : 'コードを送信',
        loginsuccess : 'ログインに成功しました！',
        logingerror : 'ログインエラー!',
        aboutS : '約{leftTime}秒',
        keepPatient : '我慢してください！',
        needMoretime : 'もっと時間が必要です!',
        aimostDoneKeep : 'もうすぐ終わりです！もう少しお待ちください！',
        usernamePlace : "ユーザー{'@'}example.com",
        emailCode : '検証コード',

    },
    feedbackDialog : {
        title : 'PixelHaha を改善するには、あなたのフィードバックが必要です。',
        subTitle1 : '改善したいですか?',
        option1 : 'アップロードされたファイルに関する不正確な顔分析',
        option2 : '似ていない',
        option3 : '顔の細部の問題（歪んでいる、目、口など）',
        option4 : 'スロータイム',
        option5 : '結果の鮮明さ',
        txtOption1 : 'センシティブまたは不快な内容',
        txtOption2 : '利用規約違反',
        txtOption3 : 'プロンプトの関連性',
        txtOption4 : '顔が歪む',
        txtOption5 : '画像コンテンツ',
        txtOption6 : '画像の鮮明度',
        txtOption7 : 'スロータイム',
        histOption1 : '悪い結果',
        histOption2 : '画像の鮮明度',
        histOption3 : 'スロータイム',
        histOption4 : '画像コンテンツ',
        subTitle2 : 'もっと言いたいことはありますか?',
        placeholder : 'その他の提案...',
        submit : '提出する',

    },
    infoDialog : {
        advancedFeatures : '高度な機能',
        choosePlan : 'プランを選択',
        insufficientCredits : 'クレジットが不足しています',
        insufficientCreditsText : 'クレジット残高:',
        getMoreCredits : 'クレジットをさらに獲得',
        videoTooBig : 'ビデオが大きすぎます',
        videoTooLong : 'ビデオが長すぎます',
        videoCannotExceed : 'ビデオは',
        inFileSize : 'ファイルサイズ',
        selectAnotherFile : '別のファイルを選択',
        photoTooBig : '写真が大きすぎます',
        photoCannotExceed : '写真は',
        tenMin : '10分',
        deleteFile : 'このファイルを削除してもよろしいですか?',
        cancel : 'キャンセル',
        confirm : '確認する',

    },
    allRightsReserved : "無断転載を禁じます。",
    aiGirlIntroductionDetails : "AI画像紹介詳細",
    aiGirlHowToUse : "AI画像の使い方",
    aiGirlFAQDetails : "AI画像に関するFAQ詳細",
    lookslikeUploadOpps : {
        title : 'おっと！',
        subtitle : 'おっと！無料クレジットの制限を超えました！\nすべての機能を無制限に使用するには、プランに加入してください。',
        unlockLimit : '制限を解除',

    },
    selectStyle : "スタイルを選択",
    purchasePopup : {
        title : '成功しました！',
        consumerSuccess : "<xx1> クレジットを獲得しました",
        subscribeSuccess : '無制限アクセス！当社のサービスをお楽しみください！',
        ok : 'わかりました',

    },
    affiliatePopup : {
        title : 'アフィリエイトプログラムチュートリアル',
        first : '初め',
        second : '2番',
        linkHint : 'アフィリエイトプログラムリンク:',
        copy : 'コピー',
        ok : 'わかりました',

    },

}
