import { createWebHistory, createRouter } from "vue-router";
import { i18n } from "@/i18n/index";

const routes = [
  {
    //根首页
    path: "/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/home/en.js")
      ).default;
      return import("./routePages/home/HomePage.vue");
    },
  },
  {
    //被邀请页
    path: "/ai-image-generator-refer/:key",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/refer/en.js")
      ).default;
      return import("./routePages/refer/RefererPage.vue");
    },
  },
  {
    //邀请页
    path: "/ai-generator-invite/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/invite/en.js")
      ).default;
      return import("./routePages/invite/InvitePage.vue");
    },
  },
  {
    //文生图-首页
    path: "/free-ai-image-generator/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/txtToImg/en.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgPage.vue");
    },
  },
  {
    //文生图-How to use二级页
    path: "/free-ai-image-generator-howToUse/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/txtToImg/en.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgHowToUsePage.vue");
    },
  },
  {
    //文生图-FAQ二级页
    path: "/free-ai-image-generator-faq/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/txtToImg/en.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgFAQPage.vue");
    },
  },
  {
    //Enhance-图片功能首页
    path: "/photo-enhance/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/enhance/en.js")
      ).default;
      return import("./routePages/enhance/EnhanceImagePage.vue");
    },
  },
  {
    //Enhance-图片功能二级页
    path: "/photo-enhance-details/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/enhance/en.js")
      ).default;
      return import("./routePages/enhance/EnhanceImageDetailsPage.vue");
    },
  },
  {
    //Enhance-视频功能首页
    path: "/video-enhance/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/enhance/en.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoPage.vue");
    },
  },
  {
    //Enhance-视频功能二级页
    path: "/video-enhance-details/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/enhance/en.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoDetailsPage.vue");
    },
  },
  {
    //站长-介绍页
    path: "/affiliate-program/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/affiliate/en.js")
      ).default;
      return import("./routePages/affiliate/AffiliateIntroducePage.vue");
    },
  },
  {
    //站长-面板页
    path: "/affiliate-program-activity/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/affiliate/en.js")
      ).default;
      return import("./routePages/affiliate/AffiliateActivityPage.vue");
    },
  },
  {
    //历史页-文生图
    path: "/history-ai-creations/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/history/en.js")
      ).default;
      return import("./routePages/history/HistoryTxtToImgPage.vue");
    },
  },
  {
    //历史页-Enhance
    path: "/history-enhance-creations/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/history/en.js")
      ).default;
      return import("./routePages/history/HistoryEnhancePage.vue");
    },
  },
  {
    //个人中心页
    path: "/account-information/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/userCenter/en.js")
      ).default;
      return import("./routePages/userCenter/UserCenterPage.vue");
    },
  },
  {
    //邮箱通知配置页
    path: "/account-information/notification/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/userCenter/en.js")
      ).default;
      return import("./routePages/userCenter/EmailNoticeSetting.vue");
    },
  },
  {
    //积分/支付历史页
    path: "/payment-credit-history/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/userCenter/en.js")
      ).default;
      return import("./routePages/userCenter/PaymentOrCreditHistoryPage.vue");
    },
  },
  {
    //付费页
    path: "/pricing/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/pricing/en.js")
      ).default;
      return import("./routePages/pricing/PricingPage.vue");
    },
  },
  {
    //联系支持页
    path: "/contact-support/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/contactSupport/en.js")
      ).default;
      return import("./routePages/contactSupport/ContactSupportPage.vue");
    },
  },
  {
    //隐私协议页
    path: "/privacy-policy/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/privacyPolicy/en.js")
      ).default;
      return import("./routePages/privacyPolicy/PrivacyPolicyPage.vue");
    },
  },
  {
    //用户协议页
    path: "/user-policy/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/userPolicy/en.js")
      ).default;
      return import("./routePages/userPolicy/UserPolicyPage.vue");
    },
  },
  {
    //退款协议页
    path: "/refund-policy/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/refundPolicy/en.js")
      ).default;
      return import("./routePages/refundPolicy/RefundPolicyPage.vue");
    },
  },
  {
    //知识产权页
    path: "/intellectual-property-rights/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/intellectualPropertyRights/en.js")
      ).default;
      return import(
        "./routePages/intellectualPropertyRights/IntellectualPropertyRightsPage.vue"
      );
    },
  },
  {
    //关于我们页
    path: "/about-us/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/aboutUs/en.js")
      ).default;
      return import("./routePages/aboutUs/AboutUs.vue");
    },
  },
  {
    //产品FAQ页
    path: "/faq/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/faq/en.js")
      ).default;
      return import("./routePages/faq/FAQPage.vue");
    },
  },
  {
    //500页
    path: "/500/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/error/en.js")
      ).default;
      return import("./routePages/error/Error500Page.vue");
    },
  },
  {
    //404页
    path: "/404/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/error/en.js")
      ).default;
      return import("./routePages/error/Error404Page.vue");
    },
  },
  {
    //支付中间响应页
    path: "/pricing-callback/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/pricing/en.js")
      ).default;
      return import("./routePages/pricing/PricingGangplankPage.vue");
    },
  },
  {
    //oceanpayment中间响应页
    path: "/oceanpayment-handler/",
    component: async () => {
      i18n.global.messages["en"] = (
        await import("/public/i18n/pricing/en.js")
      ).default;
      return import("./routePages/pricing/OceanpaymentWrapperPage.vue");
    },
  },
  {
    //根首页
    path: "/fr/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/home/fr.js")
      ).default;
      return import("./routePages/home/HomePage.vue");
    },
  },
  {
    //被邀请页
    path: "/fr/ai-image-g%C3%A9n%C3%A9rateur-r%C3%A9f%C3%A9rer/:key",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/refer/fr.js")
      ).default;
      return import("./routePages/refer/RefererPage.vue");
    },
  },
  {
    //邀请页
    path: "/fr/ai-g%C3%A9n%C3%A9rateur-inviter/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/invite/fr.js")
      ).default;
      return import("./routePages/invite/InvitePage.vue");
    },
  },
  {
    //文生图-首页
    path: "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/txtToImg/fr.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgPage.vue");
    },
  },
  {
    //文生图-How to use二级页
    path: "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur-Comment%20utiliser/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/txtToImg/fr.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgHowToUsePage.vue");
    },
  },
  {
    //文生图-FAQ二级页
    path: "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur-FAQ/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/txtToImg/fr.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgFAQPage.vue");
    },
  },
  {
    //Enhance-图片功能首页
    path: "/fr/photo-am%C3%A9liorer/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/enhance/fr.js")
      ).default;
      return import("./routePages/enhance/EnhanceImagePage.vue");
    },
  },
  {
    //Enhance-图片功能二级页
    path: "/fr/photo-am%C3%A9liorer-d%C3%A9tails/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/enhance/fr.js")
      ).default;
      return import("./routePages/enhance/EnhanceImageDetailsPage.vue");
    },
  },
  {
    //Enhance-视频功能首页
    path: "/fr/vid%C3%A9o-am%C3%A9liorer/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/enhance/fr.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoPage.vue");
    },
  },
  {
    //Enhance-视频功能二级页
    path: "/fr/vid%C3%A9o-am%C3%A9liorer-d%C3%A9tails/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/enhance/fr.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoDetailsPage.vue");
    },
  },
  {
    //站长-介绍页
    path: "/fr/filiale-programme/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/affiliate/fr.js")
      ).default;
      return import("./routePages/affiliate/AffiliateIntroducePage.vue");
    },
  },
  {
    //站长-面板页
    path: "/fr/filiale-programme-activit%C3%A9/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/affiliate/fr.js")
      ).default;
      return import("./routePages/affiliate/AffiliateActivityPage.vue");
    },
  },
  {
    //历史页-文生图
    path: "/fr/histoire-ai-cr%C3%A9ations/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/history/fr.js")
      ).default;
      return import("./routePages/history/HistoryTxtToImgPage.vue");
    },
  },
  {
    //历史页-Enhance
    path: "/fr/histoire-am%C3%A9liorer-cr%C3%A9ations/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/history/fr.js")
      ).default;
      return import("./routePages/history/HistoryEnhancePage.vue");
    },
  },
  {
    //个人中心页
    path: "/fr/compte-information/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/userCenter/fr.js")
      ).default;
      return import("./routePages/userCenter/UserCenterPage.vue");
    },
  },
  {
    //邮箱通知配置页
    path: "/fr/informations-sur-le-compte/notification/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/userCenter/fr.js")
      ).default;
      return import("./routePages/userCenter/EmailNoticeSetting.vue");
    },
  },
  {
    //积分/支付历史页
    path: "/fr/paiement-cr%C3%A9dit-histoire/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/userCenter/fr.js")
      ).default;
      return import("./routePages/userCenter/PaymentOrCreditHistoryPage.vue");
    },
  },
  {
    //付费页
    path: "/fr/tarifs/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/pricing/fr.js")
      ).default;
      return import("./routePages/pricing/PricingPage.vue");
    },
  },
  {
    //联系支持页
    path: "/fr/contact-soutien/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/contactSupport/fr.js")
      ).default;
      return import("./routePages/contactSupport/ContactSupportPage.vue");
    },
  },
  {
    //隐私协议页
    path: "/fr/confidentialit%C3%A9-politique/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/privacyPolicy/fr.js")
      ).default;
      return import("./routePages/privacyPolicy/PrivacyPolicyPage.vue");
    },
  },
  {
    //用户协议页
    path: "/fr/utilisateur-politique/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/userPolicy/fr.js")
      ).default;
      return import("./routePages/userPolicy/UserPolicyPage.vue");
    },
  },
  {
    //退款协议页
    path: "/fr/remboursement-politique/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/refundPolicy/fr.js")
      ).default;
      return import("./routePages/refundPolicy/RefundPolicyPage.vue");
    },
  },
  {
    //知识产权页
    path: "/fr/intellectuel-propri%C3%A9t%C3%A9-droits/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/intellectualPropertyRights/fr.js")
      ).default;
      return import(
        "./routePages/intellectualPropertyRights/IntellectualPropertyRightsPage.vue"
      );
    },
  },
  {
    //关于我们页
    path: "/fr/%C3%A0%20propos-nous/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/aboutUs/fr.js")
      ).default;
      return import("./routePages/aboutUs/AboutUs.vue");
    },
  },
  {
    //产品FAQ页
    path: "/fr/faq/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/faq/fr.js")
      ).default;
      return import("./routePages/faq/FAQPage.vue");
    },
  },
  {
    //500页
    path: "/fr/500/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/error/fr.js")
      ).default;
      return import("./routePages/error/Error500Page.vue");
    },
  },
  {
    //404页
    path: "/fr/404/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/error/fr.js")
      ).default;
      return import("./routePages/error/Error404Page.vue");
    },
  },
  {
    //支付中间响应页
    path: "/fr/pricing-callback/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/pricing/fr.js")
      ).default;
      return import("./routePages/pricing/PricingGangplankPage.vue");
    },
  },
  {
    //oceanpayment中间响应页
    path: "/fr/oceanpayment-handler/",
    component: async () => {
      i18n.global.messages["fr"] = (
        await import("/public/i18n/pricing/fr.js")
      ).default;
      return import("./routePages/pricing/OceanpaymentWrapperPage.vue");
    },
  },
  {
    //根首页
    path: "/es/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/home/es.js")
      ).default;
      return import("./routePages/home/HomePage.vue");
    },
  },
  {
    //被邀请页
    path: "/es/ai-imagen-generador-referirse/:key",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/refer/es.js")
      ).default;
      return import("./routePages/refer/RefererPage.vue");
    },
  },
  {
    //邀请页
    path: "/es/ai-generador-invitar/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/invite/es.js")
      ).default;
      return import("./routePages/invite/InvitePage.vue");
    },
  },
  {
    //文生图-首页
    path: "/es/gratis-ai-imagen-generador/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/txtToImg/es.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgPage.vue");
    },
  },
  {
    //文生图-How to use二级页
    path: "/es/gratis-ai-imagen-generador-C%C3%B3mo%20utilizar/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/txtToImg/es.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgHowToUsePage.vue");
    },
  },
  {
    //文生图-FAQ二级页
    path: "/es/gratis-ai-imagen-generador-Preguntas%20frecuentes/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/txtToImg/es.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgFAQPage.vue");
    },
  },
  {
    //Enhance-图片功能首页
    path: "/es/foto-mejorar/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/enhance/es.js")
      ).default;
      return import("./routePages/enhance/EnhanceImagePage.vue");
    },
  },
  {
    //Enhance-图片功能二级页
    path: "/es/foto-mejorar-detalles/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/enhance/es.js")
      ).default;
      return import("./routePages/enhance/EnhanceImageDetailsPage.vue");
    },
  },
  {
    //Enhance-视频功能首页
    path: "/es/video-mejorar/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/enhance/es.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoPage.vue");
    },
  },
  {
    //Enhance-视频功能二级页
    path: "/es/video-mejorar-detalles/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/enhance/es.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoDetailsPage.vue");
    },
  },
  {
    //站长-介绍页
    path: "/es/filial-programa/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/affiliate/es.js")
      ).default;
      return import("./routePages/affiliate/AffiliateIntroducePage.vue");
    },
  },
  {
    //站长-面板页
    path: "/es/filial-programa-actividad/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/affiliate/es.js")
      ).default;
      return import("./routePages/affiliate/AffiliateActivityPage.vue");
    },
  },
  {
    //历史页-文生图
    path: "/es/historia-ai-creaciones/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/history/es.js")
      ).default;
      return import("./routePages/history/HistoryTxtToImgPage.vue");
    },
  },
  {
    //历史页-Enhance
    path: "/es/historia-mejorar-creaciones/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/history/es.js")
      ).default;
      return import("./routePages/history/HistoryEnhancePage.vue");
    },
  },
  {
    //个人中心页
    path: "/es/cuenta-informaci%C3%B3n/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/userCenter/es.js")
      ).default;
      return import("./routePages/userCenter/UserCenterPage.vue");
    },
  },
  {
    //邮箱通知配置页
    path: "/es/informaci%C3%B3n-de-cuenta/notificaci%C3%B3n/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/userCenter/es.js")
      ).default;
      return import("./routePages/userCenter/EmailNoticeSetting.vue");
    },
  },
  {
    //积分/支付历史页
    path: "/es/pago-cr%C3%A9dito-historia/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/userCenter/es.js")
      ).default;
      return import("./routePages/userCenter/PaymentOrCreditHistoryPage.vue");
    },
  },
  {
    //付费页
    path: "/es/precios/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/pricing/es.js")
      ).default;
      return import("./routePages/pricing/PricingPage.vue");
    },
  },
  {
    //联系支持页
    path: "/es/contacto-apoyo/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/contactSupport/es.js")
      ).default;
      return import("./routePages/contactSupport/ContactSupportPage.vue");
    },
  },
  {
    //隐私协议页
    path: "/es/privacidad-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/privacyPolicy/es.js")
      ).default;
      return import("./routePages/privacyPolicy/PrivacyPolicyPage.vue");
    },
  },
  {
    //用户协议页
    path: "/es/usuario-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/userPolicy/es.js")
      ).default;
      return import("./routePages/userPolicy/UserPolicyPage.vue");
    },
  },
  {
    //退款协议页
    path: "/es/reembolso-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/refundPolicy/es.js")
      ).default;
      return import("./routePages/refundPolicy/RefundPolicyPage.vue");
    },
  },
  {
    //知识产权页
    path: "/es/intelectual-propiedad-derechos/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/intellectualPropertyRights/es.js")
      ).default;
      return import(
        "./routePages/intellectualPropertyRights/IntellectualPropertyRightsPage.vue"
      );
    },
  },
  {
    //关于我们页
    path: "/es/acerca%20de-a%20nosotros/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/aboutUs/es.js")
      ).default;
      return import("./routePages/aboutUs/AboutUs.vue");
    },
  },
  {
    //产品FAQ页
    path: "/es/preguntas%20frecuentes/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/faq/es.js")
      ).default;
      return import("./routePages/faq/FAQPage.vue");
    },
  },
  {
    //500页
    path: "/es/500/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/error/es.js")
      ).default;
      return import("./routePages/error/Error500Page.vue");
    },
  },
  {
    //404页
    path: "/es/404/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/error/es.js")
      ).default;
      return import("./routePages/error/Error404Page.vue");
    },
  },
  {
    //支付中间响应页
    path: "/es/pricing-callback/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/pricing/es.js")
      ).default;
      return import("./routePages/pricing/PricingGangplankPage.vue");
    },
  },
  {
    //oceanpayment中间响应页
    path: "/es/oceanpayment-handler/",
    component: async () => {
      i18n.global.messages["es"] = (
        await import("/public/i18n/pricing/es.js")
      ).default;
      return import("./routePages/pricing/OceanpaymentWrapperPage.vue");
    },
  },
  {
    //根首页
    path: "/pt/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/home/pt.js")
      ).default;
      return import("./routePages/home/HomePage.vue");
    },
  },
  {
    //被邀请页
    path: "/pt/ai-imagem-gerador-referir-se/:key",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/refer/pt.js")
      ).default;
      return import("./routePages/refer/RefererPage.vue");
    },
  },
  {
    //邀请页
    path: "/pt/ai-gerador-convidar/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/invite/pt.js")
      ).default;
      return import("./routePages/invite/InvitePage.vue");
    },
  },
  {
    //文生图-首页
    path: "/pt/livre-ai-imagem-gerador/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/txtToImg/pt.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgPage.vue");
    },
  },
  {
    //文生图-How to use二级页
    path: "/pt/livre-ai-imagem-gerador-como%20usar/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/txtToImg/pt.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgHowToUsePage.vue");
    },
  },
  {
    //文生图-FAQ二级页
    path: "/pt/livre-ai-imagem-gerador-Perguntas%20frequentes/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/txtToImg/pt.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgFAQPage.vue");
    },
  },
  {
    //Enhance-图片功能首页
    path: "/pt/foto-melhorar/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/enhance/pt.js")
      ).default;
      return import("./routePages/enhance/EnhanceImagePage.vue");
    },
  },
  {
    //Enhance-图片功能二级页
    path: "/pt/foto-melhorar-detalhes/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/enhance/pt.js")
      ).default;
      return import("./routePages/enhance/EnhanceImageDetailsPage.vue");
    },
  },
  {
    //Enhance-视频功能首页
    path: "/pt/v%C3%ADdeo-melhorar/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/enhance/pt.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoPage.vue");
    },
  },
  {
    //Enhance-视频功能二级页
    path: "/pt/v%C3%ADdeo-melhorar-detalhes/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/enhance/pt.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoDetailsPage.vue");
    },
  },
  {
    //站长-介绍页
    path: "/pt/afiliado-programa/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/affiliate/pt.js")
      ).default;
      return import("./routePages/affiliate/AffiliateIntroducePage.vue");
    },
  },
  {
    //站长-面板页
    path: "/pt/afiliado-programa-atividade/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/affiliate/pt.js")
      ).default;
      return import("./routePages/affiliate/AffiliateActivityPage.vue");
    },
  },
  {
    //历史页-文生图
    path: "/pt/hist%C3%B3ria-ai-cria%C3%A7%C3%B5es/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/history/pt.js")
      ).default;
      return import("./routePages/history/HistoryTxtToImgPage.vue");
    },
  },
  {
    //历史页-Enhance
    path: "/pt/hist%C3%B3ria-melhorar-cria%C3%A7%C3%B5es/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/history/pt.js")
      ).default;
      return import("./routePages/history/HistoryEnhancePage.vue");
    },
  },
  {
    //个人中心页
    path: "/pt/conta-Informa%C3%A7%C3%A3o/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/userCenter/pt.js")
      ).default;
      return import("./routePages/userCenter/UserCenterPage.vue");
    },
  },
  {
    //邮箱通知配置页
    path: "/pt/informa%C3%A7%C3%B5es-da-conta/notifica%C3%A7%C3%A3o/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/userCenter/pt.js")
      ).default;
      return import("./routePages/userCenter/EmailNoticeSetting.vue");
    },
  },
  {
    //积分/支付历史页
    path: "/pt/pagamento-cr%C3%A9dito-hist%C3%B3ria/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/userCenter/pt.js")
      ).default;
      return import("./routePages/userCenter/PaymentOrCreditHistoryPage.vue");
    },
  },
  {
    //付费页
    path: "/pt/pre%C3%A7os/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/pricing/pt.js")
      ).default;
      return import("./routePages/pricing/PricingPage.vue");
    },
  },
  {
    //联系支持页
    path: "/pt/contato-apoiar/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/contactSupport/pt.js")
      ).default;
      return import("./routePages/contactSupport/ContactSupportPage.vue");
    },
  },
  {
    //隐私协议页
    path: "/pt/privacidade-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/privacyPolicy/pt.js")
      ).default;
      return import("./routePages/privacyPolicy/PrivacyPolicyPage.vue");
    },
  },
  {
    //用户协议页
    path: "/pt/usu%C3%A1rio-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/userPolicy/pt.js")
      ).default;
      return import("./routePages/userPolicy/UserPolicyPage.vue");
    },
  },
  {
    //退款协议页
    path: "/pt/reembolso-pol%C3%ADtica/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/refundPolicy/pt.js")
      ).default;
      return import("./routePages/refundPolicy/RefundPolicyPage.vue");
    },
  },
  {
    //知识产权页
    path: "/pt/intelectual-propriedade-direitos/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/intellectualPropertyRights/pt.js")
      ).default;
      return import(
        "./routePages/intellectualPropertyRights/IntellectualPropertyRightsPage.vue"
      );
    },
  },
  {
    //关于我们页
    path: "/pt/sobre-n%C3%B3s/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/aboutUs/pt.js")
      ).default;
      return import("./routePages/aboutUs/AboutUs.vue");
    },
  },
  {
    //产品FAQ页
    path: "/pt/Perguntas%20frequentes/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/faq/pt.js")
      ).default;
      return import("./routePages/faq/FAQPage.vue");
    },
  },
  {
    //500页
    path: "/pt/500/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/error/pt.js")
      ).default;
      return import("./routePages/error/Error500Page.vue");
    },
  },
  {
    //404页
    path: "/pt/404/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/error/pt.js")
      ).default;
      return import("./routePages/error/Error404Page.vue");
    },
  },
  {
    //支付中间响应页
    path: "/pt/pricing-callback/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/pricing/pt.js")
      ).default;
      return import("./routePages/pricing/PricingGangplankPage.vue");
    },
  },
  {
    //oceanpayment中间响应页
    path: "/pt/oceanpayment-handler/",
    component: async () => {
      i18n.global.messages["pt"] = (
        await import("/public/i18n/pricing/pt.js")
      ).default;
      return import("./routePages/pricing/OceanpaymentWrapperPage.vue");
    },
  },
  {
    //根首页
    path: "/ja/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/home/ja.js")
      ).default;
      return import("./routePages/home/HomePage.vue");
    },
  },
  {
    //被邀请页
    path: "/ja/%E3%82%A2%E3%82%A4-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E5%8F%82%E7%85%A7%E3%81%99%E3%82%8B/:key",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/refer/ja.js")
      ).default;
      return import("./routePages/refer/RefererPage.vue");
    },
  },
  {
    //邀请页
    path: "/ja/%E3%82%A2%E3%82%A4-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E6%8B%9B%E5%BE%85%E3%81%99%E3%82%8B/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/invite/ja.js")
      ).default;
      return import("./routePages/invite/InvitePage.vue");
    },
  },
  {
    //文生图-首页
    path: "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/txtToImg/ja.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgPage.vue");
    },
  },
  {
    //文生图-How to use二级页
    path: "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E4%BD%BF%E3%81%84%E6%96%B9/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/txtToImg/ja.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgHowToUsePage.vue");
    },
  },
  {
    //文生图-FAQ二级页
    path: "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/txtToImg/ja.js")
      ).default;
      return import("./routePages/txtToImg/TxtToImgFAQPage.vue");
    },
  },
  {
    //Enhance-图片功能首页
    path: "/ja/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/enhance/ja.js")
      ).default;
      return import("./routePages/enhance/EnhanceImagePage.vue");
    },
  },
  {
    //Enhance-图片功能二级页
    path: "/ja/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E8%A9%B3%E7%B4%B0/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/enhance/ja.js")
      ).default;
      return import("./routePages/enhance/EnhanceImageDetailsPage.vue");
    },
  },
  {
    //Enhance-视频功能首页
    path: "/ja/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/enhance/ja.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoPage.vue");
    },
  },
  {
    //Enhance-视频功能二级页
    path: "/ja/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E8%A9%B3%E7%B4%B0/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/enhance/ja.js")
      ).default;
      return import("./routePages/enhance/EnhanceVideoDetailsPage.vue");
    },
  },
  {
    //站长-介绍页
    path: "/ja/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/affiliate/ja.js")
      ).default;
      return import("./routePages/affiliate/AffiliateIntroducePage.vue");
    },
  },
  {
    //站长-面板页
    path: "/ja/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0-%E6%B4%BB%E5%8B%95/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/affiliate/ja.js")
      ).default;
      return import("./routePages/affiliate/AffiliateActivityPage.vue");
    },
  },
  {
    //历史页-文生图
    path: "/ja/%E6%AD%B4%E5%8F%B2-%E3%81%82%E3%81%84-%E4%BD%9C%E5%93%81/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/history/ja.js")
      ).default;
      return import("./routePages/history/HistoryTxtToImgPage.vue");
    },
  },
  {
    //历史页-Enhance
    path: "/ja/%E6%AD%B4%E5%8F%B2-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E4%BD%9C%E5%93%81/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/history/ja.js")
      ).default;
      return import("./routePages/history/HistoryEnhancePage.vue");
    },
  },
  {
    //个人中心页
    path: "/ja/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88-%E6%83%85%E5%A0%B1/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/userCenter/ja.js")
      ).default;
      return import("./routePages/userCenter/UserCenterPage.vue");
    },
  },
  {
    //邮箱通知配置页
    path: "/ja/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E6%83%85%E5%A0%B1/%E9%80%9A%E7%9F%A5/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/userCenter/ja.js")
      ).default;
      return import("./routePages/userCenter/EmailNoticeSetting.vue");
    },
  },
  {
    //积分/支付历史页
    path: "/ja/%E6%94%AF%E6%89%95%E3%81%84-%E3%82%AF%E3%83%AC%E3%82%B8%E3%83%83%E3%83%88-%E6%AD%B4%E5%8F%B2/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/userCenter/ja.js")
      ).default;
      return import("./routePages/userCenter/PaymentOrCreditHistoryPage.vue");
    },
  },
  {
    //付费页
    path: "/ja/%E4%BE%A1%E6%A0%BC/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/pricing/ja.js")
      ).default;
      return import("./routePages/pricing/PricingPage.vue");
    },
  },
  {
    //联系支持页
    path: "/ja/%E6%8E%A5%E8%A7%A6-%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/contactSupport/ja.js")
      ).default;
      return import("./routePages/contactSupport/ContactSupportPage.vue");
    },
  },
  {
    //隐私协议页
    path: "/ja/%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/privacyPolicy/ja.js")
      ).default;
      return import("./routePages/privacyPolicy/PrivacyPolicyPage.vue");
    },
  },
  {
    //用户协议页
    path: "/ja/%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/userPolicy/ja.js")
      ).default;
      return import("./routePages/userPolicy/UserPolicyPage.vue");
    },
  },
  {
    //退款协议页
    path: "/ja/%E8%BF%94%E9%87%91-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/refundPolicy/ja.js")
      ).default;
      return import("./routePages/refundPolicy/RefundPolicyPage.vue");
    },
  },
  {
    //知识产权页
    path: "/ja/%E7%9F%A5%E7%9A%84-%E8%B2%A1%E7%94%A3-%E6%A8%A9%E5%88%A9/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/intellectualPropertyRights/ja.js")
      ).default;
      return import(
        "./routePages/intellectualPropertyRights/IntellectualPropertyRightsPage.vue"
      );
    },
  },
  {
    //关于我们页
    path: "/ja/%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-%E7%A7%81%E3%81%9F%E3%81%A1/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/aboutUs/ja.js")
      ).default;
      return import("./routePages/aboutUs/AboutUs.vue");
    },
  },
  {
    //产品FAQ页
    path: "/ja/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/faq/ja.js")
      ).default;
      return import("./routePages/faq/FAQPage.vue");
    },
  },
  {
    //500页
    path: "/ja/500/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/error/ja.js")
      ).default;
      return import("./routePages/error/Error500Page.vue");
    },
  },
  {
    //404页
    path: "/ja/404/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/error/ja.js")
      ).default;
      return import("./routePages/error/Error404Page.vue");
    },
  },
  {
    //支付中间响应页
    path: "/ja/pricing-callback/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/pricing/ja.js")
      ).default;
      return import("./routePages/pricing/PricingGangplankPage.vue");
    },
  },
  {
    //oceanpayment中间响应页
    path: "/ja/oceanpayment-handler/",
    component: async () => {
      i18n.global.messages["ja"] = (
        await import("/public/i18n/pricing/ja.js")
      ).default;
      return import("./routePages/pricing/OceanpaymentWrapperPage.vue");
    },
  },

  {
    // 捕获英文不存在页面
    path: "/:catchAll(.*)",
    redirect: "/404/",
  },
  {
    // 捕获法语不存在页面
    path: "/fr/:catchAll(.*)+",
    redirect: "/fr/404/",
  },
  {
    // 捕获西班牙语不存在页面
    path: "/es/:catchAll(.*)+",
    redirect: "/es/404/",
  },
  {
    // 捕获葡萄牙语不存在页面
    path: "/pt/:catchAll(.*)+",
    redirect: "/pt/404/",
  },
  {
    // 捕获日语语不存在页面
    path: "/ja/:catchAll(.*)+",
    redirect: "/ja/404/",
  },
];

export class RouterPageConst {
  /**
   *根首页
   */
  static Home = "HomePage";
  /**
   *被邀请页
   */
  static Referer = "RefererPage";
  /**
   *邀请页
   */
  static Invite = "InvitePage";
  /**
   *文生图-首页
   */
  static TxtToImg = "TxtToImgPage";
  /**
   *文生图-How to use二级页
   */
  static TxtToImgHowToUse = "TxtToImgHowToUsePage";
  /**
   *文生图-FAQ二级页
   */
  static TxtToImgFAQ = "TxtToImgFAQPage";
  /**
   *Enhance-图片功能首页
   */
  static EnhanceImage = "EnhanceImagePage";
  /**
   *Enhance-图片功能二级页
   */
  static EnhanceImageDetails = "EnhanceImageDetailsPage";
  /**
   *Enhance-视频功能首页
   */
  static EnhanceVideo = "EnhanceVideoPage";
  /**
   *Enhance-视频功能二级页
   */
  static EnhanceVideoDetails = "EnhanceVideoDetailsPage";
  /**
   *站长-介绍页
   */
  static AffiliateIntroduce = "AffiliateIntroducePage";
  /**
   *站长-面板页
   */
  static AffiliateActivity = "AffiliateActivityPage";
  /**
   *历史页-文生图
   */
  static HistoryTxtToImg = "HistoryTxtToImgPage";
  /**
   *历史页-Enhance
   */
  static HistoryEnhance = "HistoryEnhancePage";
  /**
   *个人中心页
   */
  static UserCenter = "UserCenterPage";
  /**
   *邮箱通知配置页
   */
  static EmailNoticeSetting = "EmailNoticeSetting";
  /**
   *积分/支付历史页
   */
  static PaymentOrCreditHistory = "PaymentOrCreditHistoryPage";
  /**
   *付费页
   */
  static Pricing = "PricingPage";
  /**
   *联系支持页
   */
  static ContactSupport = "ContactSupportPage";
  /**
   *隐私协议页
   */
  static PrivacyPolicy = "PrivacyPolicyPage";
  /**
   *用户协议页
   */
  static UserPolicy = "UserPolicyPage";
  /**
   *退款协议页
   */
  static RefundPolicy = "RefundPolicyPage";
  /**
   *知识产权页
   */
  static IntellectualPropertyRights = "IntellectualPropertyRightsPage";
  /**
   *关于我们页
   */
  static AboutUs = "AboutUs";
  /**
   *产品FAQ页
   */
  static FAQ = "FAQPage";
  /**
   *500页
   */
  static Error500 = "Error500Page";
  /**
   *404页
   */
  static Error404 = "Error404Page";
  /**
   *支付中间响应页
   */
  static PricingGangplank = "PricingGangplankPage";
  /**
   *oceanpayment中间响应页
   */
  static OceanpaymentWrapper = "OceanpaymentWrapperPage";
  /**
   *图库首页
   */
  static GalleryHome = "GalleryHome";
  /**
   *图库详情页
   */
  static GalleryDetail = "GalleryDetail";
  /**
   *图库搜索页
   */
  static GallerySearch = "GallerySearch";
  /**
   *图库热搜词页
   */
  static GalleryHot = "GalleryHot";
  /**
   *图库创作者页
   */
  static GalleryPublisher = "GalleryPublisher";
  /**
   *sitemap页
   */
  static Sitemap = "Sitemap";
}

export const routeConstMapping = {
  en: {
    HomePage: "/",
    RefererPage: "/ai-image-generator-refer/",
    InvitePage: "/ai-generator-invite/",
    TxtToImgPage: "/free-ai-image-generator/",
    TxtToImgHowToUsePage: "/free-ai-image-generator-howToUse/",
    TxtToImgFAQPage: "/free-ai-image-generator-faq/",
    EnhanceImagePage: "/photo-enhance/",
    EnhanceImageDetailsPage: "/photo-enhance-details/",
    EnhanceVideoPage: "/video-enhance/",
    EnhanceVideoDetailsPage: "/video-enhance-details/",
    AffiliateIntroducePage: "/affiliate-program/",
    AffiliateActivityPage: "/affiliate-program-activity/",
    HistoryTxtToImgPage: "/history-ai-creations/",
    HistoryEnhancePage: "/history-enhance-creations/",
    UserCenterPage: "/account-information/",
    EmailNoticeSetting: "/account-information/notification/",
    PaymentOrCreditHistoryPage: "/payment-credit-history/",
    PricingPage: "/pricing/",
    ContactSupportPage: "/contact-support/",
    PrivacyPolicyPage: "/privacy-policy/",
    UserPolicyPage: "/user-policy/",
    RefundPolicyPage: "/refund-policy/",
    IntellectualPropertyRightsPage: "/intellectual-property-rights/",
    AboutUs: "/about-us/",
    FAQPage: "/faq/",
    Error500Page: "/500/",
    Error404Page: "/404/",
    PricingGangplankPage: "/pricing-callback/",
    OceanpaymentWrapperPage: "/oceanpayment-handler/",
    GalleryHome: "/images-photos",
    GalleryDetail: "/images-photos",
    GallerySearch: "/images-photos/pictures",
    GalleryHot: "/images-photos/hot",
    GalleryPublisher: "/images-photos/publisher",
    Sitemap: "/sitemap",
  },
  fr: {
    HomePage: "/fr/",
    RefererPage: "/fr/ai-image-g%C3%A9n%C3%A9rateur-r%C3%A9f%C3%A9rer/",
    InvitePage: "/fr/ai-g%C3%A9n%C3%A9rateur-inviter/",
    TxtToImgPage: "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur/",
    TxtToImgHowToUsePage:
      "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur-Comment%20utiliser/",
    TxtToImgFAQPage: "/fr/gratuit-ai-image-g%C3%A9n%C3%A9rateur-FAQ/",
    EnhanceImagePage: "/fr/photo-am%C3%A9liorer/",
    EnhanceImageDetailsPage: "/fr/photo-am%C3%A9liorer-d%C3%A9tails/",
    EnhanceVideoPage: "/fr/vid%C3%A9o-am%C3%A9liorer/",
    EnhanceVideoDetailsPage: "/fr/vid%C3%A9o-am%C3%A9liorer-d%C3%A9tails/",
    AffiliateIntroducePage: "/fr/filiale-programme/",
    AffiliateActivityPage: "/fr/filiale-programme-activit%C3%A9/",
    HistoryTxtToImgPage: "/fr/histoire-ai-cr%C3%A9ations/",
    HistoryEnhancePage: "/fr/histoire-am%C3%A9liorer-cr%C3%A9ations/",
    UserCenterPage: "/fr/compte-information/",
    EmailNoticeSetting: "/fr/informations-sur-le-compte/notification/",
    PaymentOrCreditHistoryPage: "/fr/paiement-cr%C3%A9dit-histoire/",
    PricingPage: "/fr/tarifs/",
    ContactSupportPage: "/fr/contact-soutien/",
    PrivacyPolicyPage: "/fr/confidentialit%C3%A9-politique/",
    UserPolicyPage: "/fr/utilisateur-politique/",
    RefundPolicyPage: "/fr/remboursement-politique/",
    IntellectualPropertyRightsPage:
      "/fr/intellectuel-propri%C3%A9t%C3%A9-droits/",
    AboutUs: "/fr/%C3%A0%20propos-nous/",
    FAQPage: "/fr/faq/",
    Error500Page: "/fr/500/",
    Error404Page: "/fr/404/",
    PricingGangplankPage: "/fr/pricing-callback/",
    OceanpaymentWrapperPage: "/fr/oceanpayment-handler/",
    GalleryHome: "/fr/images-photos",
    GalleryDetail: "/fr/images-photos",
    GallerySearch: "/fr/images-photos/photos",
    GalleryHot: "/fr/images-photos/chaud",
    GalleryPublisher: "/fr/images-photos/%C3%A9diteur",
    Sitemap: "/fr/sitemap",
  },
  es: {
    HomePage: "/es/",
    RefererPage: "/es/ai-imagen-generador-referirse/",
    InvitePage: "/es/ai-generador-invitar/",
    TxtToImgPage: "/es/gratis-ai-imagen-generador/",
    TxtToImgHowToUsePage:
      "/es/gratis-ai-imagen-generador-C%C3%B3mo%20utilizar/",
    TxtToImgFAQPage: "/es/gratis-ai-imagen-generador-Preguntas%20frecuentes/",
    EnhanceImagePage: "/es/foto-mejorar/",
    EnhanceImageDetailsPage: "/es/foto-mejorar-detalles/",
    EnhanceVideoPage: "/es/video-mejorar/",
    EnhanceVideoDetailsPage: "/es/video-mejorar-detalles/",
    AffiliateIntroducePage: "/es/filial-programa/",
    AffiliateActivityPage: "/es/filial-programa-actividad/",
    HistoryTxtToImgPage: "/es/historia-ai-creaciones/",
    HistoryEnhancePage: "/es/historia-mejorar-creaciones/",
    UserCenterPage: "/es/cuenta-informaci%C3%B3n/",
    EmailNoticeSetting: "/es/informaci%C3%B3n-de-cuenta/notificaci%C3%B3n/",
    PaymentOrCreditHistoryPage: "/es/pago-cr%C3%A9dito-historia/",
    PricingPage: "/es/precios/",
    ContactSupportPage: "/es/contacto-apoyo/",
    PrivacyPolicyPage: "/es/privacidad-pol%C3%ADtica/",
    UserPolicyPage: "/es/usuario-pol%C3%ADtica/",
    RefundPolicyPage: "/es/reembolso-pol%C3%ADtica/",
    IntellectualPropertyRightsPage: "/es/intelectual-propiedad-derechos/",
    AboutUs: "/es/acerca%20de-a%20nosotros/",
    FAQPage: "/es/preguntas%20frecuentes/",
    Error500Page: "/es/500/",
    Error404Page: "/es/404/",
    PricingGangplankPage: "/es/pricing-callback/",
    OceanpaymentWrapperPage: "/es/oceanpayment-handler/",
    GalleryHome: "/es/im%C3%A1genes-fotos",
    GalleryDetail: "/es/im%C3%A1genes-fotos",
    GallerySearch: "/es/im%C3%A1genes-fotos/fotos",
    GalleryHot: "/es/im%C3%A1genes-fotos/caliente",
    GalleryPublisher: "/es/im%C3%A1genes-fotos/editor",
    Sitemap: "/es/sitemap",
  },
  pt: {
    HomePage: "/pt/",
    RefererPage: "/pt/ai-imagem-gerador-referir-se/",
    InvitePage: "/pt/ai-gerador-convidar/",
    TxtToImgPage: "/pt/livre-ai-imagem-gerador/",
    TxtToImgHowToUsePage: "/pt/livre-ai-imagem-gerador-como%20usar/",
    TxtToImgFAQPage: "/pt/livre-ai-imagem-gerador-Perguntas%20frequentes/",
    EnhanceImagePage: "/pt/foto-melhorar/",
    EnhanceImageDetailsPage: "/pt/foto-melhorar-detalhes/",
    EnhanceVideoPage: "/pt/v%C3%ADdeo-melhorar/",
    EnhanceVideoDetailsPage: "/pt/v%C3%ADdeo-melhorar-detalhes/",
    AffiliateIntroducePage: "/pt/afiliado-programa/",
    AffiliateActivityPage: "/pt/afiliado-programa-atividade/",
    HistoryTxtToImgPage: "/pt/hist%C3%B3ria-ai-cria%C3%A7%C3%B5es/",
    HistoryEnhancePage: "/pt/hist%C3%B3ria-melhorar-cria%C3%A7%C3%B5es/",
    UserCenterPage: "/pt/conta-Informa%C3%A7%C3%A3o/",
    EmailNoticeSetting:
      "/pt/informa%C3%A7%C3%B5es-da-conta/notifica%C3%A7%C3%A3o/",
    PaymentOrCreditHistoryPage: "/pt/pagamento-cr%C3%A9dito-hist%C3%B3ria/",
    PricingPage: "/pt/pre%C3%A7os/",
    ContactSupportPage: "/pt/contato-apoiar/",
    PrivacyPolicyPage: "/pt/privacidade-pol%C3%ADtica/",
    UserPolicyPage: "/pt/usu%C3%A1rio-pol%C3%ADtica/",
    RefundPolicyPage: "/pt/reembolso-pol%C3%ADtica/",
    IntellectualPropertyRightsPage: "/pt/intelectual-propriedade-direitos/",
    AboutUs: "/pt/sobre-n%C3%B3s/",
    FAQPage: "/pt/Perguntas%20frequentes/",
    Error500Page: "/pt/500/",
    Error404Page: "/pt/404/",
    PricingGangplankPage: "/pt/pricing-callback/",
    OceanpaymentWrapperPage: "/pt/oceanpayment-handler/",
    GalleryHome: "/pt/imagens-fotos",
    GalleryDetail: "/pt/imagens-fotos",
    GallerySearch: "/pt/imagens-fotos/fotos",
    GalleryHot: "/pt/imagens-fotos/quente",
    GalleryPublisher: "/pt/imagens-fotos/editor",
    Sitemap: "/pt/sitemap",
  },
  ja: {
    HomePage: "/ja/",
    RefererPage:
      "/ja/%E3%82%A2%E3%82%A4-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E5%8F%82%E7%85%A7%E3%81%99%E3%82%8B/",
    InvitePage:
      "/ja/%E3%82%A2%E3%82%A4-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E6%8B%9B%E5%BE%85%E3%81%99%E3%82%8B/",
    TxtToImgPage:
      "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF/",
    TxtToImgHowToUsePage:
      "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E4%BD%BF%E3%81%84%E6%96%B9/",
    TxtToImgFAQPage:
      "/ja/%E7%84%A1%E6%96%99-%E3%81%82%E3%81%84-%E7%94%BB%E5%83%8F-%E3%82%B8%E3%82%A7%E3%83%8D%E3%83%AC%E3%83%BC%E3%82%BF-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/",
    EnhanceImagePage:
      "/ja/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
    EnhanceImageDetailsPage:
      "/ja/%E5%86%99%E7%9C%9F-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E8%A9%B3%E7%B4%B0/",
    EnhanceVideoPage:
      "/ja/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B/",
    EnhanceVideoDetailsPage:
      "/ja/%E3%83%93%E3%83%87%E3%82%AA-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E8%A9%B3%E7%B4%B0/",
    AffiliateIntroducePage:
      "/ja/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0/",
    AffiliateActivityPage:
      "/ja/%E3%82%A2%E3%83%95%E3%82%A3%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%88-%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%A0-%E6%B4%BB%E5%8B%95/",
    HistoryTxtToImgPage:
      "/ja/%E6%AD%B4%E5%8F%B2-%E3%81%82%E3%81%84-%E4%BD%9C%E5%93%81/",
    HistoryEnhancePage:
      "/ja/%E6%AD%B4%E5%8F%B2-%E5%BC%B7%E5%8C%96%E3%81%99%E3%82%8B-%E4%BD%9C%E5%93%81/",
    UserCenterPage:
      "/ja/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88-%E6%83%85%E5%A0%B1/",
    EmailNoticeSetting:
      "/ja/%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88%E6%83%85%E5%A0%B1/%E9%80%9A%E7%9F%A5/",
    PaymentOrCreditHistoryPage:
      "/ja/%E6%94%AF%E6%89%95%E3%81%84-%E3%82%AF%E3%83%AC%E3%82%B8%E3%83%83%E3%83%88-%E6%AD%B4%E5%8F%B2/",
    PricingPage: "/ja/%E4%BE%A1%E6%A0%BC/",
    ContactSupportPage:
      "/ja/%E6%8E%A5%E8%A7%A6-%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88/",
    PrivacyPolicyPage:
      "/ja/%E3%83%97%E3%83%A9%E3%82%A4%E3%83%90%E3%82%B7%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    UserPolicyPage:
      "/ja/%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    RefundPolicyPage:
      "/ja/%E8%BF%94%E9%87%91-%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC/",
    IntellectualPropertyRightsPage:
      "/ja/%E7%9F%A5%E7%9A%84-%E8%B2%A1%E7%94%A3-%E6%A8%A9%E5%88%A9/",
    AboutUs:
      "/ja/%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-%E7%A7%81%E3%81%9F%E3%81%A1/",
    FAQPage: "/ja/%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F/",
    Error500Page: "/ja/500/",
    Error404Page: "/ja/404/",
    PricingGangplankPage: "/ja/pricing-callback/",
    OceanpaymentWrapperPage: "/ja/oceanpayment-handler/",
    GalleryHome: "/ja/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F",
    GalleryDetail: "/ja/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F",
    GallerySearch:
      "/ja/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E5%86%99%E7%9C%9F",
    GalleryHot: "/ja/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E7%86%B1%E3%81%84",
    GalleryPublisher:
      "/ja/%E7%94%BB%E5%83%8F-%E5%86%99%E7%9C%9F/%E7%99%BA%E8%A1%8C%E8%80%85",
    Sitemap: "/ja/sitemap",
  },
};

/**
 * 获取对应route的路径
 * @param {路由所对应的Key} page
 * @param {i18n code 码} locale
 */
export function routerPageURL(page, locale) {
  return routeConstMapping[locale][page];
}

/**
 * 跳转对应的route页面
 * @param {路由所对应的Key} page
 * @param {i18n code 码} locale
 * @param {是否新开页签} newTab
 */
export function goRouterPage(page, locale, newTab = false) {
  const url = routerPageURL(page, locale);
  if (newTab) {
    window.open(url);
  } else {
    window.location.href = url;
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
