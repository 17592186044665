import { ref } from 'vue';
import cookie from './cookie';

export class CommonUtility {
    static assetImgType = 'png';
    static isNarrow = ref(false);
    static ishomeNarrow = ref(false);
    static narrowBoundaryValue = 1070;
    static narrowHomeValue = 1000;
    static isMobile = false;
    static isWebview = false;

    static init () {
        this.isMobile = window.mobileAndTabletCheck();
        this.isWebview = window.isWebview();
        this.narrowChangeListener();
    }

    static getUrlRelativePath () {
        
        let url = document.location.toString();
        let arrUrl = url.split('//');
        let start = arrUrl[1].indexOf('/');
        let relUrl = arrUrl[1].substring(start);
        if (relUrl.indexOf('?') != -1) {
            relUrl = relUrl.split('?')[0];
        }
        return relUrl;
    }

    static getUrlRelativeLastComponentPath () {
        let path = this.getUrlRelativePath();
        let arr = path.split('/');
        return arr[1]
    }

    static geti18nComponentPath () {
        let path = this.getUrlRelativePath().toLowerCase();
        let arr = path.split('/');
        let langs = [
            'en', 'fr', 'ja', 'es', 'pt',
        ];
        for (let lang of langs) {
            if (arr.indexOf(lang) != '-1') {
                return lang;
            }
        }
    }

    static getQueryVariable (variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0;i < vars.length;i++) {
            let pair = vars[i].split('=');
            if(pair[0] == variable) {
return pair[1];
}
        }
        return(false);
    }

    static narrowChangeListener () {
        this.isNarrow.value = window.innerWidth < this.narrowBoundaryValue;
        this.ishomeNarrow.value = window.innerWidth < this.narrowHomeValue;
        window.addEventListener('resize', () => {
            this.isNarrow.value = window.innerWidth < this.narrowBoundaryValue;
            this.ishomeNarrow.value = window.innerWidth < this.narrowHomeValue;
        });
    }

    static async checkWebpSupport () {
        let handle = new Promise((resolve) => {
            let img = new Image();
            img.onload = function () {
                let isSupported = (img.width > 0) && (img.height > 0);
                if (isSupported) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };
            img.onerror = function () {
                resolve(false);
            };
            img.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
        });
        let supoort = await handle;
        this.assetImgType = supoort ? 'webp' : 'png';
        return supoort;
    }

    static assetImage (name) {
        return require(`@/assets/images/${this.assetImgType}/${name}.${this.assetImgType}`);
    }

    static getUuid () {
        let uuid = window.localStorage.uuid;
        if (!uuid) {
            uuid = this.makeUuid();
            window.localStorage.uuid = uuid;
            cookie.setItem('udid', uuid, Infinity, '/', window.location.hostname);
        }
        return uuid;
    }

    static makeUuid () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (Math.random() * 16) | 0;
            let v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    /**
     * 过滤中文
     * @param str 需要过滤的目标串
     * @returns {string} 处理结果
     */
    static replaceUtZh (str = '') {
     return  str.replace(/[\u4e00-\u9fa5]/g, '')
    }

    static regUserName(str = '') {
        return str.replace( /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/g)
    }

    static getToDayStamp(){
        // 获取今天的日期
        const today = new Date();
        today.setHours(0, 0, 0, 0); // 设置时间为午夜
        // 获取从午夜开始到现在的时间差（毫秒）
        const diffMs = new Date() - today;

        // 将毫秒转换为秒
        const secondsSinceMidnight = Math.floor(diffMs / 1000);
        return secondsSinceMidnight

    }

    static getYesterDayStamp(){
        // 创建一个表示当前时间的 Date 对象
        let currentDate = new Date();
        // 设置当前时间到今天的午夜
        currentDate.setHours(0, 0, 0, 0);
        // 将当前时间戳减去一天的时间（86400秒 * 1000毫秒/秒）
        currentDate.setTime(currentDate.getTime() - (24 * 60 * 60 * 1000));
        // 获取昨天的毫秒时间戳
        const yesterdayTimestampMs = currentDate.getTime();
        // 转换成秒时间戳
        const yesterdayTimestampSec = Math.floor(yesterdayTimestampMs / 1000);
        return yesterdayTimestampSec

    }

    static getPastTimesStamp(datNum = 0){
        if (!isNaN(parseFloat(datNum)) && !isFinite(datNum)){
            return 0
        }

        // 创建一个表示当前时间的 Date 对象
        let currentDate = new Date();

        let yesterdayTimestampMs = 0
        if (datNum == 0){
            // 将当前时间戳减去一天的时间（86400秒 * 1000毫秒/秒）
            yesterdayTimestampMs = new Date().getTime();
            // 获取昨天的毫秒时间戳
        } else if (datNum == 1){
            currentDate.setHours(0, 0, 0, 0)
            yesterdayTimestampMs = currentDate.getTime()
        } else if (datNum == 2){
            currentDate.setHours(0, 0, 0, 0)
            yesterdayTimestampMs = currentDate.getTime() - 1000
        } else  if (datNum == 3){
            currentDate.setHours(0, 0, 0, 0)
            yesterdayTimestampMs = currentDate.getTime() - (24 * 60 * 60 * 1000)
        } else {
            // 设置当前时间到今天的午夜
            currentDate.setHours(0, 0, 0, 0);
            // 将当前时间戳减去一天的时间（86400秒 * 1000毫秒/秒）
            currentDate.setTime(currentDate.getTime() - ((24 * (datNum - 1)) * 60 * 60 * 1000));
            yesterdayTimestampMs = currentDate.getTime();
        }

        // 转换成秒时间戳
        const yesterdayTimestampSec = Math.floor(yesterdayTimestampMs / 1000);
        return yesterdayTimestampSec

    }
    static formatDate(dateTime, formatString) {
        let date = new Date(dateTime * 1000)
        const padZero = (num) => (num < 10 ? '0' + num : num);

        return formatString.replace(/(yyyy|yy|MM?|dd?|HH?|hh?|mm?|ss?)/g, (match) => {
            switch (match) {
                case 'yyyy':
                    return date.getFullYear();
                case 'yy':
                    return String(date.getFullYear()).slice(-2);
                case 'MM':
                    return padZero(date.getMonth() + 1);
                case 'M':
                    return date.getMonth() + 1;
                case 'dd':
                    return padZero(date.getDate());
                case 'd':
                    return date.getDate();
                case 'HH':
                    return padZero(date.getHours());
                case 'H':
                    return date.getHours();
                case 'hh':
                    let hour = date.getHours();
                    return padZero(hour % 12 || 12);
                case 'h':
                    hour = date.getHours();
                    return hour % 12 || 12;
                case 'mm':
                    return padZero(date.getMinutes());
                case 'm':
                    return date.getMinutes();
                case 'ss':
                    return padZero(date.getSeconds());
                case 's':
                    return date.getSeconds();
                default:
                    return match;
            }
        });
    }

    static getPastTimes(num){

        return this.formatDate(this.getPastTimesStamp(num), "MM/dd/yyyy")
    }
}