export default {
    meta : {
        title : "PixelHaha",
        keywords : "Gerador de arte de IA inspirado na sua ideia",
        description : "Gerador de arte de IA inspirado na sua ideia",

    },
    siteName : "PixelHaha",
    creation : "Imagem de IA",
    product : "Produto",
    magicBrush : "Pincel Mágico",
    pricing : "Preços",
    freeCredits : "Créditos grátis",
    credits : "Créditos",
    getMoreCredits : "Obtenha mais créditos",
    left : "Esquerda",
    upgradeToPremium : "Atualize para Premium",
    go : "Ir!",
    accountInformation : "Informações da conta",
    signOut : "Sair",
    getMore : "Obtenha mais",
    contactSupport : "Entre em contato com o suporte",
    history : "História",
    login : "Conecte-se",
    signup : "Inscrever-se",
    aiFuture : "Recurso de IA",
    aIImageGenerator : "Gerador de imagens de IA",
    imageEnhacner : "Melhorador de imagem de IA",
    videoEnhacner : "Aprimorador de vídeo de IA",
    language : "Linguagem",
    support : "Apoiar",
    userPolicy : "Política do Usuário",
    privacyPolicy : "política de Privacidade",
    refundPolicy : "Política de Reembolso",
    blog : "Blogue",
    helpAndFAQS : "Ajuda e FAQS",
    siteMap : "Mapa do Site",
    bussiness : "Negócios",
    affiliateProgram : "Programa de Afiliados",
    api : "API",
    contactUs : "Contate-nos",
    details : "Detalhes >",
    chooseFace : "Escolha o rosto",
    tryAgain : "Tentar novamente",
    choose : {
        delText : 'Tem certeza de que deseja excluir este rosto?',
        leftBtnText : 'Continuar',
        rightBtnText : 'Cancelar',
        overFaces : 'O upload de rostos atingiu o limite. Exclua outros rostos para continuar.',
        faceAn : 'Analisando rosto...',
        facesuccess : 'Sucesso na análise facial',
        facefailed : 'Análise de rosto falhou',
        feedback : 'Obrigado pelo seu feedback!',
        copy : 'Cópia realizada com sucesso!',
        batchsupportAIGirl : 'Suportado em lote! Você pode criar uma nova imagem de IA, não importa o resultado atual.',
        submitting : 'Enviando',
        queuing : 'Fila',
        creating : 'Criando',
        downloading : 'Baixando',
        retryText : 'Algo deu errado, tente novamente mais tarde.',
        retry : 'Tentar novamente',
        emailTip : 'Insira um sufixo de nome de domínio válido (como .com, .net, etc.)',
        noaccount : 'Ainda não tem uma conta?',
        enterPassWord : 'Digite a senha',
        passWordErr : 'Definir senha com no mínimo 8 caracteres',
        loginPassWordErr : 'A senha deve ter pelo menos 8 caracteres',
        forgotpass : 'Esqueceu sua senha?',
        googlesign : 'Efetuar login com o Google Sign-In?',
        resetpassword : 'Redefinir senha',
        readyaccount : 'Já tem uma conta?',
        confirm : 'Confirmar',
        resetpasswordsuccess : 'Redefinição de senha realizada com sucesso!',
        senderror : 'Erro de envio!',
        sendsuccess : 'Enviar com sucesso!',
        signupfree : 'Cadastre-se gratuitamente',
        signupsuccess : 'Inscrição bem-sucedida!',
        sendcode : 'Enviar código',
        loginsuccess : 'Efetuou login com sucesso!',
        logingerror : 'Erro de login!',
        aboutS : 'sobre {leftTime}s',
        keepPatient : 'Seja paciente!',
        needMoretime : 'Preciso de mais tempo!',
        aimostDoneKeep : 'Quase pronto! Mantenha a paciência!',
        usernamePlace : "usuário{'@'}exemplo.com",
        emailCode : 'Código de verificação',

    },
    feedbackDialog : {
        title : 'Precisamos do seu feedback para melhorar o PixelHaha.',
        subTitle1 : 'Quer melhorar?',
        option1 : 'Análise facial imprecisa sobre arquivos enviados',
        option2 : 'Não parece',
        option3 : 'Problemas com detalhes do rosto (distorcidos, olhos, boca etc.)',
        option4 : 'Tempo lento',
        option5 : 'Nitidez do resultado',
        txtOption1 : 'Conteúdo sensível ou perturbador',
        txtOption2 : 'Violação dos termos de serviço',
        txtOption3 : 'Relevância imediata',
        txtOption4 : 'Rosto Distorcido',
        txtOption5 : 'Conteúdo da imagem',
        txtOption6 : 'Nitidez da imagem',
        txtOption7 : 'Tempo lento',
        histOption1 : 'Resultados ruins',
        histOption2 : 'Nitidez da imagem',
        histOption3 : 'Tempo lento',
        histOption4 : 'Conteúdo da imagem',
        subTitle2 : 'Quer dizer mais?',
        placeholder : 'Outras sugestões...',
        submit : 'Enviar',

    },
    infoDialog : {
        advancedFeatures : 'Recursos avançados',
        choosePlan : 'Escolha o plano',
        insufficientCredits : 'Créditos insuficientes',
        insufficientCreditsText : 'Seu saldo credor:',
        getMoreCredits : 'Obtenha mais créditos',
        videoTooBig : 'O vídeo é muito grande',
        videoTooLong : 'O vídeo é muito longo',
        videoCannotExceed : 'O vídeo não pode exceder',
        inFileSize : 'em tamanho de arquivo',
        selectAnotherFile : 'Selecione outro arquivo',
        photoTooBig : 'A foto é muito grande',
        photoCannotExceed : 'A foto não pode exceder',
        tenMin : '10 minutos',
        deleteFile : 'Tem certeza de que deseja excluir este arquivo?',
        cancel : 'Cancelar',
        confirm : 'Confirmar',

    },
    allRightsReserved : "Todos os direitos reservados.",
    aiGirlIntroductionDetails : "Detalhes da introdução da imagem AI",
    aiGirlHowToUse : "Imagem AI Como usar",
    aiGirlFAQDetails : "Detalhes das perguntas frequentes sobre imagens de IA",
    lookslikeUploadOpps : {
        title : 'Ops!',
        subtitle : 'Oops! Excedeu os limites de créditos grátis!\nPor favor, assine o plano para usar todos os recursos ilimitadamente.',
        unlockLimit : 'Limite de desbloqueio',

    },
    selectStyle : "Selecione o estilo",
    purchasePopup : {
        title : 'Com sucesso!',
        consumerSuccess : "Você ganhou <xx1> créditos",
        subscribeSuccess : 'Acesso ilimitado! Aproveite nosso serviço!',
        ok : 'OK',

    },
    affiliatePopup : {
        title : 'Tutorial do Programa de Afiliados',
        first : 'Primeiro',
        second : 'Segundo',
        linkHint : 'Link do Programa de Afiliados:',
        copy : 'Cópia',
        ok : 'OK',

    },

}
