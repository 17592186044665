export default {
    meta : {
        title : "PixelJaja",
        keywords : "Generador de arte con IA inspirado en tu idea",
        description : "Generador de arte con IA inspirado en tu idea",

    },
    siteName : "PixelJaja",
    creation : "Imagen de IA",
    product : "Producto",
    magicBrush : "Pincel mágico",
    pricing : "Precios",
    freeCredits : "Créditos gratis",
    credits : "Créditos",
    getMoreCredits : "Obtenga más créditos",
    left : "Izquierda",
    upgradeToPremium : "Actualizar a Premium",
    go : "¡Ir!",
    accountInformation : "Información de la cuenta",
    signOut : "Desconectar",
    getMore : "Obtenga más",
    contactSupport : "Contactar con el servicio de asistencia",
    history : "Historia",
    login : "Acceso",
    signup : "Inscribirse",
    aiFuture : "Función de IA",
    aIImageGenerator : "Generador de imágenes con IA",
    imageEnhacner : "Mejorador de imágenes con IA",
    videoEnhacner : "Mejorador de video con IA",
    language : "Idioma",
    support : "Apoyo",
    userPolicy : "Política de usuario",
    privacyPolicy : "política de privacidad",
    refundPolicy : "Política de reembolso",
    blog : "Blog",
    helpAndFAQS : "Ayuda y preguntas frecuentes",
    siteMap : "Mapa del sitio",
    bussiness : "Negocios",
    affiliateProgram : "Programa de afiliados",
    api : "API",
    contactUs : "Contáctenos",
    details : "Detalles >",
    chooseFace : "Elige la cara",
    tryAgain : "Intentar otra vez",
    choose : {
        delText : '¿Estás seguro de eliminar esta cara?',
        leftBtnText : 'Continuar',
        rightBtnText : 'Cancelar',
        overFaces : 'Las caras cargadas han alcanzado el límite, elimine otras caras para continuar.',
        faceAn : 'Analizando rostro...',
        facesuccess : 'Análisis facial exitoso',
        facefailed : 'Análisis facial fallido',
        feedback : '¡Gracias por tus comentarios!',
        copy : '¡Copia exitosa!',
        batchsupportAIGirl : '¡Compatible con lotes! Puedes crear una nueva imagen de IA sin importar el resultado actual.',
        submitting : 'Sumisión',
        queuing : 'Haciendo cola',
        creating : 'Creando',
        downloading : 'Descargando',
        retryText : 'Algo salió mal, por favor inténtalo de nuevo más tarde.',
        retry : 'Rever',
        emailTip : 'Ingrese un sufijo de nombre de dominio válido (como .com, .net, etc.)',
        noaccount : '¿Aún no tienes cuenta?',
        enterPassWord : 'Introducir contraseña',
        passWordErr : 'Establecer contraseña 8 caracteres mínimo',
        loginPassWordErr : 'La contraseña debe tener al menos 8 caracteres',
        forgotpass : '¿Has olvidado tu contraseña?',
        googlesign : '¿Iniciar sesión con Google Sign-In?',
        resetpassword : 'Restablecer contraseña',
        readyaccount : '¿Ya tienes una cuenta?',
        confirm : 'Confirmar',
        resetpasswordsuccess : 'Restablecer contraseña ¡Éxito!',
        senderror : 'Enviar Error!',
        sendsuccess : 'Enviar ¡Éxito!',
        signupfree : 'Regístrate gratis',
        signupsuccess : '¡Registro exitoso!',
        sendcode : 'Enviar código',
        loginsuccess : '¡Iniciar sesión exitosamente!',
        logingerror : '¡Error de inicio de sesión!',
        aboutS : 'acerca de {leftTime}s',
        keepPatient : '¡Mantén la paciencia!',
        needMoretime : '¡Necesitamos más tiempo!',
        aimostDoneKeep : '¡Ya casi está! ¡Ten paciencia!',
        usernamePlace : "usuario{'@'}ejemplo.com",
        emailCode : 'Código de verificación',

    },
    feedbackDialog : {
        title : 'Necesitamos tus comentarios para mejorar PixelHaha.',
        subTitle1 : '¿Quieres mejorar?',
        option1 : 'Análisis facial inexacto en los archivos cargados',
        option2 : 'No parece',
        option3 : 'Problemas con los detalles del rostro (distorsionado, ojos, boca, etc.)',
        option4 : 'Tiempo lento',
        option5 : 'Nitidez del resultado',
        txtOption1 : 'Contenido sensible o perturbador',
        txtOption2 : 'Violación de los términos del servicio',
        txtOption3 : 'Relevancia inmediata',
        txtOption4 : 'Cara distorsionada',
        txtOption5 : 'Contenido de la imagen',
        txtOption6 : 'Nitidez de la imagen',
        txtOption7 : 'Tiempo lento',
        histOption1 : 'Malos resultados',
        histOption2 : 'Nitidez de la imagen',
        histOption3 : 'Tiempo lento',
        histOption4 : 'Contenido de la imagen',
        subTitle2 : '¿Quieres decir más?',
        placeholder : 'Otras sugerencias...',
        submit : 'Entregar',
        
    },
    infoDialog : {
        advancedFeatures : 'Funciones avanzadas',
        choosePlan : 'Elija un plan',
        insufficientCredits : 'Créditos insuficientes',
        insufficientCreditsText : 'Su saldo a favor:',
        getMoreCredits : 'Obtenga más créditos',
        videoTooBig : 'El video es demasiado grande',
        videoTooLong : 'El vídeo es demasiado largo',
        videoCannotExceed : 'El video no puede exceder',
        inFileSize : 'en tamaño de archivo',
        selectAnotherFile : 'Seleccione otro archivo',
        photoTooBig : 'La foto es demasiado grande',
        photoCannotExceed : 'La foto no puede exceder',
        tenMin : '10 minutos',
        deleteFile : '¿Estás seguro de eliminar este archivo?',
        cancel : 'Cancelar',
        confirm : 'Confirmar',

    },
    allRightsReserved : "Reservados todos los derechos.",
    aiGirlIntroductionDetails : "Detalles de la introducción de imágenes de IA",
    aiGirlHowToUse : "Cómo utilizar una imagen de IA",
    aiGirlFAQDetails : "Detalles de las preguntas frecuentes sobre imágenes con IA",
    lookslikeUploadOpps : {
        title : '¡Ups!',
        subtitle : '¡Ups! ¡Se han excedido los límites de créditos gratuitos!\nSuscríbete al plan para usar todas las funciones de forma ilimitada.',
        unlockLimit : 'Límite de desbloqueo',

    },
    selectStyle : "Seleccionar estilo",
    purchasePopup : {
        title : '¡Exitosamente!',
        consumerSuccess : "Has ganado <xx1> créditos",
        subscribeSuccess : '¡Acceso ilimitado! ¡Disfruta de nuestro servicio!',
        ok : 'DE ACUERDO',

    },
    affiliatePopup : {
        title : 'Tutorial del programa de afiliados',
        first : 'Primero',
        second : 'Segundo',
        linkHint : 'Enlace del programa de afiliados:',
        copy : 'Copiar',
        ok : 'DE ACUERDO',

    },

}
