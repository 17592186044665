import { CommonUtility }  from '@/script/utility';
import setup from '@/script/setup';
import i18n from '@/i18n/index';
import app from '@/app';
import router from "./routes";

CommonUtility.checkWebpSupport().then(() => {
    app.use(setup);
    app.use(i18n);
    app.use(router);
    app.mount('#app');
});