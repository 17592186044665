import { initializeApp } from "@firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ENV } from "./proj_env.js";
import { User } from "./user.js";

export class AnalyticHub {
  static get getInstance() {
    if (!this.instance) {
      this.instance = new AnalyticHub();
      this.instance.page = "meming";
      this.instance.initComplete = false;
      this.instance.buckets = [];
    }
    return this.instance;
  }

  static initAnalytic() {
    AnalyticHub.getInstance.initAnalytic();
  }

  initAnalytic() {
    this.initFirebase();
    // this.initMeta();
  }

  initFirebase() {
    if (this.initComplete) return;
    const firebaseConfig = ENV.currentConfig.firebaseConfig;
    const app = initializeApp(firebaseConfig);

    this.firAnalytic = getAnalytics(app);
    this.initComplete = true;
    if (this.buckets.length) {
      for (const item of this.buckets) {
        logEvent(AnalyticHub.getInstance.firAnalytic, item.name, item.params);
      }
      this.buckets = [];
    }
  }

  // async initMeta () {
  //     await this.setupPixel();
  //     const token = window.localStorage.token;
  //     this.fbSetup(ENV.currentConfig.fbID, token | '');
  // }

  //     async setupPixel () {
  //         return new Promise((resolve) => {
  //           !(function (f, b, e, v, n, t, s) {
  //               if (f.fbq) return; n = f.fbq = function () {
  //                   n.callMethod
  //                   ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  //               };
  //               if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  //               n.queue = []; t = b.createElement(e); t.async = !0;
  //               t.src = v; s = b.getElementsByTagName(e)[0];
  //               t.async = true;
  //               t.defer = true;
  //               t.onload = function () {
  //                 resolve();
  //               };
  //               s.parentNode.insertBefore(t, s);
  //           }(
  // window, document, 'script',
  //               'https://connect.facebook.net/en_US/fbevents.js'
  // ));
  //         });
  //     }

  logApp() {
    if ((localStorage.getItem("analyze")?.length || 0) > 0) {
      const cache = JSON.parse(localStorage.getItem("analyze"));
      for (const item of cache) {
        AnalyticHub.getInstance.log(item.name, item.params);
      }
      localStorage.removeItem("analyze");
    }
    AnalyticHub.getInstance.log("guest_log_app", { time: "0" });
  }

  log(name, params, storage = false) {
    if (!params) {
      params = {};
    }
    console.log(ENV.isProd, params);
    if (!ENV.isProd) {
      console.log(`🐳 埋点: ${name} `, params);
    }
    if (User.getInstance.isLogin.value) {
      params.membersid = User.getInstance.token.value;
    }
    params.pays = User.getInstance.hasPaid ? 1 : 0;
    if (storage) {
      const cache = {
        name: name,
        params: params,
      };
      let cacheList = [];
      if ((localStorage.getItem("analyze")?.length || 0) > 0) {
        cacheList = JSON.parse(localStorage.getItem("analyze"));
      }
      cacheList.push(cache);
      localStorage.setItem("analyze", JSON.stringify(cacheList));
    } else {
      if (!this.initComplete) {
        this.buckets.push({
          name: name,
          params: params,
        });
      } else {
        if (this.buckets.length) {
          for (const item of this.buckets) {
            logEvent(
              AnalyticHub.getInstance.firAnalytic,
              item.name,
              item.params
            );
          }
          this.buckets = [];
        }
        logEvent(AnalyticHub.getInstance.firAnalytic, name, params);
      }
    }
    // this.fbLogEvent(name, params);
  }

  // fbSetup (pixelId, token) {
  //     if (token) {
  //         window.fbq('init', pixelId, { external_id : token });
  //     }else {
  //         window.fbq('init', pixelId);
  //     }
  //     window.fbq('track', 'PageView');
  // }

  // fbLogEvent (name, params) {
  //     window.fbq('trackCustom', name, params);
  // }
}
