import { reactive, createApp, h } from 'vue';
import Modal from './Modal.vue';
import installI18n, { i18n } from '@/i18n/basic/index';
import { CommonUtility } from '@/script/utility';
import ToastPlugin from '@/components/common/toast/toast';

const modalState = reactive({
  isVisible: false,
  component: null,
  props: {},
});

export const showModal = (component, props = {}) => {
  modalState.component = component;
  modalState.props = props;
  modalState.isVisible = true;
};

export const closeModal = () => {
  modalState.isVisible = false;
  modalState.component = null;
  modalState.props = {};
};

const ModalPlugin = {
  install(app) {
    app.config.globalProperties.$modal = {
      show: showModal,
      close: closeModal,
    };

    const modalComponent = createApp({
      render() {
        return h(Modal, { modalState });
      },
    });
    modalComponent.use(installI18n);
    modalComponent.use(ToastPlugin);
    modalComponent.config.globalProperties.i18n = i18n;
    modalComponent.config.globalProperties.$utility = CommonUtility;
    
    const modalDiv = document.createElement('div');
    document.body.appendChild(modalDiv);
    modalComponent.mount(modalDiv);
    
  },
};

export default ModalPlugin;
