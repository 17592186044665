<template>
    <div class="normal" :class="[status, { highlightHover: supportHover }]" @click="$emit('onClick')">
        <slot></slot>
    </div>
</template>


<script>
export default {

    name: 'FyButton',
    props: ['status', 'supportHover'],
    emits: ['onClick'],
};
</script>


<style scoped lang="scss">
.normal {
    width: auto;
    display: inline-block;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    background: #F4F5F5;
    font-size: 20px;
    font-weight: 500;

    text-align: center;
    border: 0;
}

.highlight {
    background: #FF7839;
}

.unable {
  background: #FFBB9C;
    color: #FFFFFF;
}

.highlightHover:hover {
    background: #E15E1E;
}
</style>
