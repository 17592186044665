<template>
  <teleport to="body">
      <div :class="['modal-overlay', {popin: modalState.isVisible, popout: !modalState.isVisible}]" :offstage="!modalState.isVisible && !animating" @click.self="close">
        <transition name="modal-slide" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div v-if="modalState.isVisible" class="modal">
            <component :is="modalState.component" v-bind="modalState.props"></component>
          </div>
        </transition>
      </div>
  </teleport>
</template>

<script setup>
import { ref, watch } from 'vue';

const animating = ref(false)
const props = defineProps({
  modalState: Object,
});

watch(() => props.modalState.isVisible, () => {
  document.body.style.overflow = props.modalState.isVisible ? 'hidden' : 'auto';    
  animating.value = true
  setTimeout(() => {
    animating.value = false
  }, 650)
})

const close = () => {
  props.modalState.isVisible = false;
  props.modalState.component = null;
  props.modalState.props = {};
};


</script>

<style>
/* 定义动画 */
@keyframes popin {
  from {
    opacity: 0; /* 起始透明度 */
  }
  to {
    opacity: 1; /* 结束透明度 */
  }
}
@keyframes popout {
  from {
    opacity: 1; /* 起始透明度 */
  }
  to {
    opacity: 0; /* 结束透明度 */
  }
}
.popin {
    animation: popin .5s ease-in-out;
    animation-fill-mode: forwards;
}
.popout {
    animation: popout .5s ease-in-out;
    animation-fill-mode: forwards;
}

.modal-overlay[offstage=true] {
  display: none;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  margin-bottom: 8%;
  transform: translateY(0%);
  transition: transform 0.5s;
}

/* 弹窗内容从底部向上弹出动画 */
.modal-slide-enter-active, .modal-slide-leave-active {
  transition: transform 0.5s;
}

.modal-slide-enter-from, .modal-slide-leave-to {
  transform: translateY(100%);
}
</style>
